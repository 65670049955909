<template>
  <div class="address-page">
    <div :class="{ blur: showPin }"></div>
    <v-dialog max-width="350" v-model="dialog">
      <v-card height="300px" class="px-4">
        <v-row no-gutters justify="center" align="center" class="h-100">
          <v-col cols="12" class="text-center" v-if="dialog">
            <p style="font-size: 1rem">This is your address</p>
            <input
              type="text"
              class="input-pvkey"
              ref="privatekey"
              readonly
              :value="address"
            />
            <p style="font-size: 1rem" class="mt-4">
              ส่ง Address ไปยัง {{ dest }}
            </p>
            <div class="mt-4">
              <a
                rel="noreffer"
                :href="
                  `${dest}${
                    !dest.includes('?')
                      ? `?wallet_address=${address}`
                      : `&wallet_address=${address}`
                  }`
                "
                style="text-decoration: none;"
                ><v-btn color="#2CD97B" dark>ตกลง</v-btn></a
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <pin-pad
      v-if="showPin"
      header="กรุณาใส่รหัส"
      :verify="true"
      @verifyPin="verifyPin"
    />
  </div>
</template>
<script>
import pidPad from "../components/pinPad.vue";
export default {
  name: "SendAddress",
  components: {
    "pin-pad": pidPad,
  },
  data() {
    return {
      showPin: true,
      address: null,
      dialog: false,
      dest: null,
    };
  },
  async mounted() {
    const email = localStorage.getItem("email_account_mpv");
    if (email === null) {
      try {
        await this.alert_show({
          type: "errordontlogin",
          title: "กรุณาเข้าสู่ระบบก่อนทำรายการหรือสมัครบริการ",
          header: "ล้มเหลว !",
        });
        location.replace(this.$route.query.dest);
      } catch (err) {}
    }
    this.dest = this.$route.query.dest;
  },
  methods: {
    async verifyPin(verify) {
      try {
        if (verify.status) {
          this.showPin = false;
          await this.app_loading(true);
          let wallet = await decrypt(
            localStorage.getItem("encypt_string_mpv"),
            verify.password
          );
          let wallet_json = JSON.parse(wallet);
          this.address = wallet_json.address;
          this.app_loading(false);
          this.dialog = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
function decrypt(message = "", key = "") {
  var code = CryptoJS.AES.decrypt(message, key);
  var decryptedMessage = code.toString(CryptoJS.enc.Utf8);

  return decryptedMessage;
}
</script>

<style lang="scss">
.address-page {
  min-height: 100vh;
  height: 100%;
  background-color: #222766;
  padding-bottom: 70px;
}
.input-pvkey {
  border: 2px solid #2cd97b !important;
  background-color: white;
  width: 90%;
  border-radius: 50px;
  text-align: center;
  color: #6d6b6b;
  padding: 2px;
  font-size: 16px;
  max-width: 250px;
}
</style>
